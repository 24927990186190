<template>
  <div class="toolProduction nav_tab_wrap">
    <el-tabs class="nav_wrap" type="border-card" v-model="activeName" @tab-click="handleClick">
      <!-- <el-tab-pane label="成品生产" name="finishedOrder"> -->
      <el-tab-pane label="生产计划" name="finishedOrder">
        <finishedOrder v-if="isPO"></finishedOrder>
      </el-tab-pane>
      <el-tab-pane label="生产执行" name="productionExecution">
        <productionExecution :disabledbo="disabledbo" v-if="isExecution"></productionExecution>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import finishedOrder from "./finishedOrder/Index";
import productionExecution from "../toolProduction/productionExecution/Index";
export default {
  name: "finishedProduction",

  data() {
    return {
      disabledbo: true,
      activeName: 'finishedOrder',
      isPO: true
    };
  },
  components: {
    finishedOrder,
    productionExecution
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    handleClick(tab) {
      switch (tab.name) {
        case 'finishedOrder':
          this.isPO = true;
          this.isExecution=false;
          break;
          case 'productionExecution':
          this.isExecution=true;
          this.isPO = false;
          break;
        default:
          break;
      }
    }
  }
};
</script>